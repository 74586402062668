import { graphql } from 'gatsby';
import Articles from '../../views/news/articles';

export default Articles;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    articles: allContentfulArticle(sort: { order: DESC, fields: publishDate }) {
      edges {
        node {
          title
          category
          slug
          titleInfo
          publishDate
          authors {
            title
            file {
              url
            }
          }
          thumbnail {
            title
            file {
              url
            }
          }
          readTime
          node_locale
        }
      }
    }
  }
`;
