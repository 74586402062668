import React, { useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import {
  I18nextContext,
  Trans,
  useTranslation
} from 'gatsby-plugin-react-i18next';
import Layout from '../../../components/layout/layout';
import {
  PageSection,
  SectionWrapper
} from '../../../components/global/flexbox';
import { Highlight } from '../components/highlight';
import { BtnWrapper, Flex, PageWrapper } from '../styled.components';
import { theme } from '../../../themes/default';
import getCurrentTranslations from '../../../components/contentful-translator';
import { HighlightWrapper } from '../components/highlightWrapper';
import Breadcrumbs from '../../../components/global/breadcrumbs';
import { Button } from '../../../components/global/button';
import { navigateTo } from '../../../components/global/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { SectionHeader } from '../../../components/typography';
import Newsletter from '../../../components/newsletter';
import Seo from '../../../components/seo';

import BgImg from '../../../images/twoToneTwo.png';
import { Page } from '../../../components/global/page';
export default function Articles({ data }) {
  const { t } = useTranslation();
  const { language } = useContext(I18nextContext);

  const articles = getCurrentTranslations(data.articles.edges, language);
  const medtech = articles.filter((article) => {
    if (article?.node?.category === 'medtech') {
      return article;
    }
    return null;
  });
  const beginner = articles.filter((article) => {
    if (article?.node?.category === 'beginner') {
      return article;
    }
    return null;
  });

  const breadcrumbsPath = [
    { name: t('breadcrumbs.news'), path: '/news/' },
    { name: t('breadcrumbs.articles'), path: '/articles' }
  ];
  const pageData = [
    {
      header: t`news.article.our-articles`,
      type: 'news',
      data: articles,
      loadmore: true
    },
    {
      header: t`news.article.for-amateurs`,
      type: 'articles',
      data: beginner,
      loadmore: true
    },
    {
      header: t`news.article.meet-medtech`,
      type: 'medtech',
      data: medtech,
      loadmore: true
    }
  ];
  return (
    <Layout withHeader={false}>
      <Seo
        title={t`news.articles.seo.header`}
        description={t`news.articles.seo.description`}
      />
      <Page className="articlesPage" style={{ marginLeft: 0, marginRight: 0 }}>
        <ThemeProvider theme={theme}>
          <PageWrapper>
            <SectionWrapper style={{ width: 'auto' }}>
              <Breadcrumbs path={breadcrumbsPath} />
            </SectionWrapper>
            {pageData.map((section) => {
              return (
                <HighlightWrapper
                  key={section.type}
                  link={section.link}
                  title={<Trans t={t}>{section.header}</Trans>}
                  component={
                    <Highlight
                      data={section.data}
                      type={section.type}
                      loadmore={section.loadmore}
                    />
                  }
                />
              );
            })}
            <PageSection className="two-tone">
              <SectionWrapper>
                <Flex bg_img={BgImg}>
                  <div className="white">
                    <span>{t`news.cooperate.text`}</span>
                    <SectionHeader>{t`news.two-tone.header`}</SectionHeader>
                    <p>{t`news.two-tone.text`}</p>
                    <BtnWrapper>
                      <Button
                        className="solid"
                        onClick={() => navigateTo('/contact')}>
                        {t`news.write-to-us-text`}
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                      <Button
                        className="empty"
                        onClick={() => navigateTo('/career')}>
                        {t`news.know-more-text`}
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                    </BtnWrapper>
                  </div>
                  <div className="blue">
                    <SectionHeader>{t`news.two-tone.header-two`}</SectionHeader>
                    <p>{t`news.two-tone.text-two`}</p>
                    <Newsletter />
                  </div>
                </Flex>
              </SectionWrapper>
            </PageSection>
          </PageWrapper>
        </ThemeProvider>
      </Page>
    </Layout>
  );
}
